import cn from 'classnames';
import React, { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldPath, useFormContext } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { Button, ButtonProps } from '@react-md/button';
import { FeatureContent } from '@components/feature-content';
import { OrderForm, AvailableInitialOrderStatuses } from '@types';
import { getNodeIdComposer, setApiErrors } from '@utils';
import { orderActions, selectAdditionalContactsFormAttributes, selectAdditionalContactsList } from '@modules/order';
import { selectIsRequiredDrawingsAbsent, selectProductsHasSanityNotifications } from '@modules/product';
import { OrderFormId } from '@pages/widget/order-submit/order-forms/constants';
import { useOrderSubmitLabels } from './hooks';
import { RootPrefix } from '../constants';
import { useOrderSubmitContext } from '../order-submit-page';

export const _id = getNodeIdComposer(RootPrefix, 'submit_order');

export interface OrderSubmitProps {
    disabled: boolean;
    statuses: AvailableInitialOrderStatuses;
}

export const OrderSubmit: React.FC<OrderSubmitProps> = ({ disabled, statuses }) => {
    const dispatch = useAppDispatch();
    const tableRef = useOrderSubmitContext();
    const isRequiredDrawingsAbsent = useAppSelector(selectIsRequiredDrawingsAbsent);
    const hasSanityNotifications = useAppSelector(selectProductsHasSanityNotifications);
    const additionalContactsList = useAppSelector(selectAdditionalContactsList);
    const { errors, watch } = useAppSelector(selectAdditionalContactsFormAttributes);
    const { handleSubmit, setError } = useFormContext<OrderForm>();

    const handleClick: MouseEventHandler<HTMLButtonElement> = event => {
        const status = event?.currentTarget.value;

        handleSubmit((form, event) => {
            if (isRequiredDrawingsAbsent || hasSanityNotifications) {
                const firstProductWithoutDrawing = tableRef?.current?.querySelector('.error_to_scroll_to');
                firstProductWithoutDrawing?.scrollIntoView({ behavior: 'smooth', block: 'center' });
                return;
            }

            if (errors && errors('additional_contact').error) {
                return;
            }

            if (watch && watch('additional_contact')) {
                dispatch(
                    orderActions.setAdditionalContactsList([...additionalContactsList, watch('additional_contact')]),
                );
            }

            dispatch(
                orderActions.submit({
                    form: {
                        ...form,
                        status,
                    },
                    onError: errors => {
                        setApiErrors(setError, errors as Record<FieldPath<OrderForm>, string>);
                    },
                }),
            );
        })(event);
    };

    const { t } = useTranslation();
    const labels = useOrderSubmitLabels(t);

    const buttonBaseProps: ButtonProps = {
        form: OrderFormId,
        onClick: handleClick,
        name: 'status',
        type: 'button',
        disabled: disabled,
    };

    const buttonClasses = ['rmd-button--text-large', 'w-full'];

    if (statuses.length === 1) {
        const status = statuses[0];
        const id = _id(status);
        return (
            <FeatureContent
                contentKey={id}
                fallback={labels[status]}
                wrapper={props => (
                    <Button
                        {...buttonBaseProps}
                        theme="primary"
                        themeType="contained"
                        className={cn(buttonClasses)}
                        value={status}
                        {...props}
                    />
                )}
            />
        );
    }

    return (
        <>
            {statuses.map((status, i) => {
                const id = _id(status);
                const buttonStyleProps: ButtonProps =
                    i % 2 === 0
                        ? {
                              id,
                              theme: 'primary',
                              themeType: 'outline',
                              className: cn(buttonClasses),
                          }
                        : {
                              id,
                              theme: 'secondary',
                              themeType: 'contained',
                              className: cn(buttonClasses),
                          };

                return (
                    <FeatureContent
                        key={status}
                        contentKey={id}
                        fallback={labels[status]}
                        wrapper={props => (
                            <Button {...buttonBaseProps} {...buttonStyleProps} value={status} {...props} />
                        )}
                    />
                );
            })}
        </>
    );
};
