enum Header {
    HowItWorksShown = 'header-how_it_works-shown',
    LanguageShown = 'header-language-shown',
    LogoShown = 'header-logo-shown',
    MyAccountShown = 'header-my_account-shown',
}

enum SignUp {
    FirstNameRequired = 'sign_up-first_name-required',
    LastNameRequired = 'sign_up-last_name-required',
    PhoneRequired = 'sign_up-phone-required',
}

enum InvoicePage {
    EstimatedManufacturingTimeShown = 'invoice_page-estimated_manufacturing_time-shown',
    SidebarAskForHelpShown = 'invoice_page-sidebar_ask_for_help-shown',
    InvoicePdfPopupShown = 'invoice_page-invoice_pdf_popup-shown',
    PoFileRequired = 'invoice_page-sidebar-PO_file-required',
}

enum CartPage {
    ResizeModel = 'cart_page-resize_model-shown',
    PricingPerPartShown = 'cart_page-pricing_per_part-shown',
    PricingPostProductionShown = 'cart_page-pricing_post_production-shown',
    PricingProductionPriorityShown = 'cart_page-pricing_production_priority-shown',
    PricingStartupCostShown = 'cart_page-pricing_startup_cost-shown',
    PricingSubtotalShown = 'cart_page-pricing_subtotal-shown',
    ApplyToAllModelsOn = 'cart_page-apply_to_all_models-on',
    BulkPriceOn = 'cart_page-bulk_price-on',
    BulkPriceExpand = 'cart_page-bulk_price-expand',
    WallThicknessOnlyFailedShown = 'cart_page-wall_thickness_only_failed-shown',
    RotationModeShown = 'cart_page-rotation_mode-shown',
    SidebarAskForHelpShown = 'cart_page-sidebar_ask_for_help-shown',
    SpecificationColorFieldAsList = 'cart_page-color-as_list',
}

enum OrderPage {
    PickupAddressShown = 'order_page-pickup_address-shown',
    PersonalInfoUneditable = 'order_page-personal_info-uneditable',
    ProductBatchDiscountShown = 'order_page-product_batch_discount-shown',
    SidebarAskForHelpShown = 'order_page-sidebar_ask_for_help-shown',
}

enum MaterialSpecificationFieldsShown {
    'cnc_features_types' = 'cart_page-cnc_features-shown',
    'layer_thickness' = 'cart_page-layer_thickness-shown',
    'filling' = 'cart_page-filling-shown',
    'tolerance' = 'cart_page-tolerance-shown',
    'thickness' = 'cart_page-thickness-shown',
    'lead_time' = 'cart_page-lead_time-shown',
    'color' = 'cart_page-color-shown',
    'extra_fieldsets' = 'cart_page-extra_fieldsets-shown',
    'post_production' = 'cart_page-post_production-shown',
}

enum Viewer {
    // initial state of dark mode
    DarkModeOn = '3d_viewer-darkmode-on',

    // initial states of objects on the scene
    AxesShown = '3d_viewer-axes-shown',
    GridFloorShown = '3d_viewer-floor_grid-shown',
    ModelBoxShown = '3d_viewer-model_box-shown',
    PrinterBoxShown = '3d_viewer-printer_box-shown',

    // viewer mode buttons visibility
    SolidModeTabShown = '3d_viewer-solid_mode_tab-shown',
    XrayModeTabShown = '3d_viewer-xray_mode_tab-shown',
    WireframeModeTabShown = '3d_viewer-wireframe_mode_tab-shown',

    // viewer controls visibility
    AxesBtnShown = '3d_viewer-axes_btn-shown',
    DarkModeBtnShown = '3d_viewer-darkmode_btn-shown',
    GridFloorBtnShown = '3d_viewer-floor_grid_btn-shown',
    ModelBoxBtnShown = '3d_viewer-model_box_btn-shown',
    PrinterBoxBtnShown = '3d_viewer-printer_box_btn-shown',
    RenderingBtnShown = '3d_viewer-rendering_btn-shown',
}

export const FeatureFlagDefaultValue = true;
export const FeatureFlagsDefaults: Record<string, boolean> = {
    [Viewer.DarkModeOn]: false,
    [OrderPage.PersonalInfoUneditable]: false,
    [CartPage.WallThicknessOnlyFailedShown]: false,
    [CartPage.BulkPriceExpand]: false,
    [CartPage.ApplyToAllModelsOn]: false,
    [SignUp.FirstNameRequired]: false,
    [SignUp.LastNameRequired]: false,
    [SignUp.PhoneRequired]: false,
    // [InvoicePage.InvoicePdfPopupShown]: false,
} as const;

export const ObjectsFeature = {
    ModelEdgesColor: '3d_viewer-model_edges-color',
    ModelColor: '3d_viewer-model_color',
    SceneBackgroundColor: '3d_viewer-scene_background-color',
} as const;

export const FeatureKeys = {
    Header,
    SignUp,
    CartPage,
    OrderPage,
    InvoicePage,
    Viewer,
    MaterialSpecificationFieldsShown,
} as const;
