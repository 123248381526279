import invariant from 'tiny-invariant';
// import * as Three from 'three';
import {
    Box3,
    Color,
    ColorManagement,
    Euler,
    Matrix4,
    MathUtils,
    Quaternion,
    Vector3,
    MeshBasicMaterial,
    MeshPhongMaterial,
    ShaderMaterial,
    WebGLRenderer,
    PerspectiveCamera,
    Object3D,
    Group,
    Scene,
    AmbientLight,
    DirectionalLight,
    Mesh,
    DoubleSide,
    AdditiveBlending,
    Float32BufferAttribute,
    GridHelper,
    BufferAttribute,
} from 'three';
import merge from 'lodash/merge';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { TransformControls } from 'three/examples/jsm/controls/TransformControls';
import { RecursivePartial } from '@types';
import { jsonLoader } from './json-loader';
import { modelsLoader } from './models-loader';
import { getRotationSteps, objectCleanup } from './helpers';
import {
    ModelMaterials,
    ModelViewerConfig,
    ModelViewerObjects,
    ModelViewerRenderMode,
    ModelViewerVariables as MVV,
} from './constants';
import { Axes, Grid, ModelBoundingBox, ModelEdges, PrinterBoundingBox } from './objects';
import { Rotation } from './types';
import events from './events';

// https://stackoverflow.com/questions/8272297/three-js-rotate-projection-so-that-the-y-axis-becomes-the-z-axis/57402467#57402467
Object3D.DEFAULT_UP.set(0, 0, 1);

export class ModelViewer {
    private config: Readonly<typeof ModelViewerConfig>;

    // scene
    public canvasWrapperElement?: HTMLDivElement;
    public renderer?: WebGLRenderer;
    public scene: Scene = new Scene();
    public camera: PerspectiveCamera = new PerspectiveCamera(
        45,
        this.container.offsetWidth / this.container.offsetHeight,
        1,
        40000,
    );
    public controls?: OrbitControls;
    public transformControls?: TransformControls;
    public objects: Partial<{
        [ModelViewerObjects.Axes]: {
            object: Axes;
            active: boolean;
        };
        [ModelViewerObjects.Grid]: {
            object: Grid;
            active: boolean;
        };
        [ModelViewerObjects.ModelBoundingBox]: {
            object: ModelBoundingBox;
            active: boolean;
        };
        [ModelViewerObjects.ModelEdges]: {
            object: ModelEdges;
            active: boolean;
        };
        [ModelViewerObjects.PrinterBoundingBox]: {
            object: PrinterBoundingBox;
            active: boolean;
        };
        [ModelViewerObjects.Model]: {
            object: Group | Mesh;
            active: boolean;
        };
    }> = {};
    private fieldSize = 4000;

    // model
    public currentModelBBox = new Box3();
    public currentModel?: Group | Mesh;
    public sourceModelMatrix?: Matrix4;
    public modelMaterials: {
        [ModelViewerRenderMode.Solid]?: MeshPhongMaterial;
        [ModelViewerRenderMode.XRay]?: ShaderMaterial;
        [ModelViewerRenderMode.Wireframe]?: MeshBasicMaterial;
    } = {};
    public modelInitialQuaternion = new Quaternion();
    public isModelFitted = true;
    public hasBaseMaterial = true;
    public wallThinFaces?: number[];

    // viewer
    private renderRequested = false;
    private _renderMode?: ModelViewerRenderMode;
    private _darkModeOn?: boolean;
    private _rotateModeOn?: boolean;
    private _highlightThinWallsOn = false;

    constructor(readonly container: HTMLDivElement, config?: RecursivePartial<typeof ModelViewerConfig>) {
        this.config = merge({}, ModelViewerConfig, config);

        this.handleViewerError = this.handleViewerError.bind(this);
        this.modelSetup = this.modelSetup.bind(this);
        this.handleRotateModel = this.handleRotateModel.bind(this);
        this.render = this.render.bind(this);
        this.requestRenderIfNotRequested = this.requestRenderIfNotRequested.bind(this);
        this.resetModelTransform = this.resetModelTransform.bind(this);

        try {
            const renderer = this.createRenderer();
            this.renderer = renderer;
            renderer && this.init(renderer);
        } catch (e) {
            this.handleViewerError(e);
            this.renderer && this.clear();
        }
    }

    private createRenderer() {
        try {
            return new WebGLRenderer({
                antialias: true,
                // alpha: true,
            });
        } catch (e) {
            this.handleViewerError(e);
            return new WebGLRenderer();
        }
    }

    private handleViewerError(error: unknown) {
        console.log(error);
    }

    private async init(renderer: WebGLRenderer) {
        // renderer.setPixelRatio( window.devicePixelRatio );
        // renderer.setSize(this.container.offsetWidth, this.container.offsetHeight, false);

        this.canvasWrapperElement = this.createCanvasWrapperElement();
        this.canvasWrapperElement.appendChild(renderer.domElement); // inserts canvas element to DOM
        this.container.append(this.canvasWrapperElement);

        // TODO change to https://www.npmjs.com/package/camera-controls
        this.controls = this.createControls();
        this.transformControls = this.createTransformControls();

        this.attachObject(ModelViewerObjects.Axes, this.scene);
        this.attachObject(ModelViewerObjects.Grid, this.scene);
        this.attachObject(ModelViewerObjects.ModelBoundingBox, this.scene);
        this.attachObject(ModelViewerObjects.PrinterBoundingBox, this.scene);

        this.createAmbientLight(0xffffff, 2.2);

        window.addEventListener('resize', this.requestRenderIfNotRequested);

        this.updateCameraPosition(new Vector3().setScalar(500));

        this.darkModeOn = this.config.darkModeOn;
        this.rotateModeOn = this.config.rotateModeOn;
        this.renderMode = this.config.renderMode;
        this.debug();

        events.emitMvSceneInit({ success: true });
    }

    private createCanvasWrapperElement() {
        const div = document.createElement('div');
        div.classList.add('visualizer');
        return div;
    }

    private createControls() {
        const controls = new OrbitControls(this.camera, this.canvasWrapperElement);

        controls.rotateSpeed = 1;
        controls.zoomSpeed = 4;
        controls.panSpeed = 2;
        // controls.enableDamping = true;
        // controls.target.set(0, 0, 0);
        controls.update();

        controls.addEventListener('change', this.requestRenderIfNotRequested);

        return controls;
    }

    private createTransformControls() {
        const transformControls = new TransformControls(this.camera, this.canvasWrapperElement);
        // transformControls.space = 'local';
        transformControls.setMode('rotate');
        transformControls.setRotationSnap(MathUtils.degToRad(5));
        transformControls.addEventListener('change', this.requestRenderIfNotRequested);

        const orbitControls = this.controls;
        const scope = this;

        transformControls.addEventListener('dragging-changed', function (event) {
            if (orbitControls) orbitControls.enabled = !event.value;

            // scope.calculateModelBBox(transformControls.object);
            !event.value && scope.handleRotateModel();
        });

        transformControls.addEventListener('rotationAngle-changed', function (event) {
            scope.calculateModelBBox(transformControls.object);
            // scope.handleRotateModel();

            events.emitMvModelRotationChange(getRotationSteps(scope.getModelRotation(transformControls.object)));
        });

        this.scene.add(transformControls);

        return transformControls;
    }

    loadModel(modelFileUrl: string, modelSetupOptions = { enableEdgesRendering: true, enableBaseMaterial: true }) {
        const handler = modelsLoader.load(modelFileUrl);
        handler.promise
            .then(model => {
                this.modelSetup(model, modelSetupOptions);
                return model;
            })
            .catch(error => {
                this.handleViewerError(error);
                return error;
            });

        return handler;
    }

    loadThinWalls(wallsFileUrl: string) {
        const handler = jsonLoader.load<number[]>(wallsFileUrl);
        handler.promise
            .then(data => {
                this.wallThinFaces = data;
                return data;
            })
            .catch(error => {
                this.handleViewerError(error);
                return error;
            });

        return handler;
    }

    modelCleanup() {
        if (!this.currentModel) return;

        objectCleanup(this.currentModel);
        this.currentModel.removeFromParent();
        this.currentModel = undefined;

        this.debug();
    }

    modelSetup(
        object: Mesh | Group,
        modelSetupOptions: { enableEdgesRendering?: boolean; enableBaseMaterial?: boolean },
    ) {
        const { enableBaseMaterial, enableEdgesRendering } = modelSetupOptions;

        this.modelCleanup();

        this.currentModel = object;
        this.currentModel.name = 'model';
        this.currentModel.renderOrder = 4;
        this.currentModel.scale.set(1, 1, 1);
        // this.currentModel.matrixAutoUpdate = false;

        this.calculateModelBBox(); // calc initial model BB
        this.sourceModelMatrix = object.matrix.clone();
        this.modelInitialQuaternion = object.quaternion.clone();

        this.hasBaseMaterial = !!enableBaseMaterial;

        if (enableBaseMaterial) {
            this.changeObjectMaterial(object, this.getModelMaterial(this.renderMode)!);
        }

        this.scene.add(this.currentModel);

        const cameraPosition = this.calculateCameraPosition();
        this.updateCameraPosition(cameraPosition);

        this.updateOrCreateDirectionalLight('directionalLightFront', cameraPosition, 0xffffff, 1.8);
        this.updateOrCreateDirectionalLight(
            'directionalLightBack',
            cameraPosition.clone().multiplyScalar(-1),
            0xffffff,
            1.8,
        );

        if (enableEdgesRendering) {
            this.attachObject(ModelViewerObjects.ModelEdges, this.currentModel);
        }

        // this.rotateModel([], this.currentModel);

        // this.centerSceneObjects();
        this.requestRenderIfNotRequested();
        this.debug();
    }

    getObjectBoundingBoxSize() {
        const bbox = this.currentModelBBox;
        const bboxSize = new Vector3();
        bbox.getSize(bboxSize);
        return bboxSize;
    }

    private calculateCameraPosition() {
        const bboxSize = this.getObjectBoundingBoxSize();

        let longestAxis = bboxSize.x;
        if (longestAxis < bboxSize.y) longestAxis = bboxSize.y;
        if (longestAxis < bboxSize.z) longestAxis = bboxSize.z;

        return new Vector3().setScalar(longestAxis * 1.7);
    }

    private updateCameraPosition(position: Vector3, lookAt?: Vector3) {
        this.camera.position.copy(position);

        if (this.controls) {
            this.controls.target.copy(lookAt ?? this.scene.position);
        } else {
            this.camera.lookAt(lookAt ?? this.scene.position);
        }
    }

    resizeRendererToDisplaySize(renderer: WebGLRenderer) {
        const canvas = renderer.domElement;
        const width = this.container.offsetWidth;
        const height = this.container.offsetHeight;
        const needResize = canvas.width !== width || canvas.height !== height;

        if (needResize) {
            renderer.setSize(width, height, false);
        }

        return needResize;
    }

    updateCameraAspect() {
        this.camera.aspect = this.container.offsetWidth / this.container.offsetHeight;
        this.camera.updateProjectionMatrix();
    }

    private render() {
        this.renderRequested = false;

        const renderer = this.renderer;
        if (!renderer) return;

        if (this.resizeRendererToDisplaySize(renderer)) {
            this.updateCameraAspect();
        }

        // this.stats?.update();
        this.controls?.update();
        // for helpers update && update()

        renderer.render(this.scene, this.camera);
    }

    private getModelRotation(model?: Object3D) {
        model = model ?? this.currentModel;
        return model && new Euler().setFromQuaternion(model.quaternion, 'ZYX');
    }

    handleRotateModel() {
        if (!this.currentModel) return;

        this.currentModel.position.set(0, 0, 0); // reset model position to correct shift
        this.calculateModelBBox(); // calc BB after transform

        const shift = this.currentModelBBox.min.clone().multiplyScalar(-1);
        this.currentModel.position.copy(shift);
        this.calculateModelBBox(); // calc BB after shift

        this.printerContainsModel();
        this.requestRenderIfNotRequested();
    }

    printerContainsModel() {
        const printer = this.objects[ModelViewerObjects.PrinterBoundingBox]?.object;
        this.isModelFitted = Boolean(printer?.checkModelContains(this.currentModelBBox));

        events.emitMvModelFitsIntoPrinter(this.isModelFitted);
    }

    animate(onRender?: () => void) {
        const scope = this;

        function _animate() {
            onRender && onRender();
            scope.render();
        }

        this.renderer?.setAnimationLoop(_animate);
    }

    requestRenderIfNotRequested() {
        if (!this.renderRequested) {
            this.renderRequested = true;
            requestAnimationFrame(this.render);
        }
    }

    get rotateModeOn() {
        return this._rotateModeOn ?? this.config.rotateModeOn;
    }

    set rotateModeOn(on: boolean) {
        this._rotateModeOn = on;

        if (on) {
            this.renderMode = ModelViewerRenderMode.Solid;
            this.transformControls?.attach(this.currentModel!);
            this.printerContainsModel();
        } else {
            this.resetModelTransform();
            this.transformControls?.detach();
        }
    }

    resetModelTransform() {
        this.currentModel?.position.set(0, 0, 0);
        this.currentModel?.quaternion.copy(this.modelInitialQuaternion);
        this.calculateModelBBox();
        this.printerContainsModel();
        this.requestRenderIfNotRequested();

        events.emitMvModelRotationChange(getRotationSteps(this.getModelRotation()));
    }

    private calculateModelBBox(model?: Object3D) {
        model = model ?? this.currentModel;
        model && this.currentModelBBox.setFromObject(model, true);
    }

    // todo dispatch event darkModeChanged, move objects code
    private toggleDarkMode(on: boolean) {
        const gridObject = this.scene.getObjectByName('grid')!;

        const sceneColor = on
            ? MVV.Scene.DarkBackgroundColor
            : this.config.customBackgroundColor || MVV.Scene.LightBackgroundColor;

        const grid1Color = on ? MVV.Grid.DarkColor : MVV.Grid.CellColor;
        const grid2Color = on ? MVV.Grid.DarkColor : MVV.Grid.SectionColor;

        (gridObject.children[0] as GridHelper).material.color.set(grid1Color);
        (gridObject.children[1] as GridHelper).material.color.set(grid2Color);

        this.changeSceneBackground(sceneColor);
    }

    get darkModeOn() {
        return this._darkModeOn ?? this.config.darkModeOn;
    }

    set darkModeOn(on: boolean) {
        this._darkModeOn = on;
        this.toggleDarkMode(on);
        this.requestRenderIfNotRequested();
    }

    get renderMode() {
        return this._renderMode ?? this.config.renderMode;
    }

    set renderMode(mode: ModelViewerRenderMode) {
        this._renderMode = mode;

        switch (mode) {
            case ModelViewerRenderMode.Solid: {
                this.changeMaterialRenderMode({
                    mode,
                    objectsVisibility: true,
                });
                break;
            }
            case ModelViewerRenderMode.XRay:
            case ModelViewerRenderMode.Wireframe: {
                this.changeMaterialRenderMode({
                    mode,
                    objectsVisibility: false,
                    forceDarkMode: true,
                });
                break;
            }

            default: {
                const _exhaustiveCheck: never = mode;
            }
        }
    }

    get solidModeActive() {
        return this.renderMode === ModelViewerRenderMode.Solid;
    }

    get xRayModeActive() {
        return this.renderMode === ModelViewerRenderMode.XRay;
    }

    get wireframeModeActive() {
        return this.renderMode === ModelViewerRenderMode.Wireframe;
    }

    get highlightThinWallsOn() {
        return this._highlightThinWallsOn;
    }

    set highlightThinWallsOn(on: boolean) {
        this._highlightThinWallsOn = on;
        this.toggleHighlightThinWalls();
        this.requestRenderIfNotRequested();
    }

    private toggleHighlightThinWalls() {
        const mesh = this.currentModel as Mesh;

        if (!this.wallThinFaces || !mesh) {
            return;
        }

        const clearMaterialColor = 0xffffff;
        const currentMaterialColor = this.config.objects[ModelViewerObjects.Model].color || MVV.Model.Color;

        if (this._highlightThinWallsOn) {
            const faceColor = new Color(MVV.Model.ThinFaceColor);
            const materialColorRGB = new Color(currentMaterialColor).toArray();

            mesh.geometry.setAttribute(
                'color',
                new Float32BufferAttribute(
                    Array.from(
                        { length: (mesh.geometry.attributes.position as BufferAttribute).array.length },
                        (_, i) => materialColorRGB[i % materialColorRGB.length],
                    ),
                    3,
                ),
            );

            const colorAttributes = mesh.geometry.getAttribute('color') as BufferAttribute;

            this.wallThinFaces.forEach(faceIndex => {
                colorAttributes.setXYZ(faceIndex * 3, faceColor.r, faceColor.g, faceColor.b);
                colorAttributes.setXYZ(faceIndex * 3 + 1, faceColor.r, faceColor.g, faceColor.b);
                colorAttributes.setXYZ(faceIndex * 3 + 2, faceColor.r, faceColor.g, faceColor.b);
            });
        } else {
            mesh.geometry.deleteAttribute('color');
        }

        (mesh.material as MeshPhongMaterial).setValues({
            vertexColors: this._highlightThinWallsOn,
            color: this._highlightThinWallsOn ? clearMaterialColor : currentMaterialColor,
        });

        (mesh.material as MeshPhongMaterial).needsUpdate = true;
    }

    changeMaterialRenderMode({
        mode,
        objectsVisibility,
        forceDarkMode = false,
    }: {
        mode: ModelViewerRenderMode;
        objectsVisibility: boolean;
        forceDarkMode?: boolean;
    }) {
        Object.values(this.objects)
            .filter(object => object.active)
            .forEach(object => {
                this.changeObjectVisibility(object.object, objectsVisibility);
            });

        if (this.currentModel && this.hasBaseMaterial) {
            this.changeObjectMaterial(this.currentModel, this.getModelMaterial(mode)!);
        }

        if (forceDarkMode) {
            !this.darkModeOn && this.toggleDarkMode(true);
        } else {
            // restore user's choice, when returning to solid mode
            !this.darkModeOn && this.toggleDarkMode(false);
        }

        this.requestRenderIfNotRequested();
    }

    getModelMaterial(mode: ModelViewerRenderMode) {
        if (this.modelMaterials[mode]) {
            return this.modelMaterials[mode];
        }

        const customMaterialColor = this.config.objects[ModelViewerObjects.Model].color;

        switch (mode) {
            case ModelViewerRenderMode.Solid: {
                this.modelMaterials[mode] = new MeshPhongMaterial({
                    color: customMaterialColor || MVV.Model.Color,
                    specular: 0xffffff,
                    shininess: 3.5,
                    flatShading: true,
                });
                break;
            }
            case ModelViewerRenderMode.Wireframe: {
                this.modelMaterials[mode] = new MeshBasicMaterial({
                    color: new Color(0xffffff),
                    wireframe: true,
                });
                break;
            }
            case ModelViewerRenderMode.XRay: {
                const uniforms = {
                    p: { value: 3 },
                    glowColor: { value: new Color(0xffffff) },
                };

                const vertexShaderGLSL = `
                    uniform float p;
                    varying float intensity;
                    void main() {
                        vec3 vNormal = normalize( normalMatrix * normal );
                        intensity = pow(1.1 - abs(dot(vNormal, vec3(0, 0, 1))), p);
                        gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
                      }`;

                const fragmentShaderGLSL = `
                    uniform vec3 glowColor;
                    varying float intensity;
                    void main() {
                        vec3 glow = glowColor * intensity;
                        gl_FragColor = vec4( glow, .5 );
                      }`;

                this.modelMaterials[mode] = new ShaderMaterial({
                    uniforms: uniforms,
                    vertexShader: vertexShaderGLSL,
                    fragmentShader: fragmentShaderGLSL,
                    side: DoubleSide,
                    blending: AdditiveBlending,
                    depthWrite: false,
                });
                break;
            }

            default: {
                const _exhaustiveCheck: never = mode;
                return _exhaustiveCheck;
            }
        }

        return this.modelMaterials[mode];
    }

    // centerSceneObjects() {
    //     if (!this.currentModel) return;
    //
    //     const bbox = new Box3().setFromObject(this.currentModel);
    //     const bboxCenter = bbox.getCenter(this.currentModel.position);
    //
    //     if (this.currentModel.matrixAutoUpdate) {
    //         this.currentModel.position.set(-bboxCenter.x, -bboxCenter.y, 0);
    //     } else {
    //         const rotationMatrix = new Matrix4();
    //         const translated = new Matrix4().makeTranslation(-bboxCenter.x, -bboxCenter.y, 0);
    //         this.currentModel.applyMatrix4(rotationMatrix.multiply(translated));
    //     }
    //
    //     // printer box center
    //
    //     this.requestRenderIfNotRequested();
    // }

    clear() {
        invariant(this.renderer, 'Renderer not created!');

        if (this.modelMaterials) {
            Object.values(this.modelMaterials).forEach(material => material.dispose());
            this.modelMaterials = {};
        }

        for (let object of this.scene.children) {
            objectCleanup(object);
        }

        this.objects = {};
        this.scene.clear();

        this.transformControls?.dispose();

        this.renderer.setAnimationLoop(null);
        this.renderer.renderLists.dispose();
        this.renderer.dispose();

        this.controls?.removeEventListener('change', this.requestRenderIfNotRequested);
        this.transformControls?.removeEventListener('change', this.requestRenderIfNotRequested);
        window.removeEventListener('resize', this.requestRenderIfNotRequested);

        this.container.removeChild(this.canvasWrapperElement!);

        this.debug();

        events.emitMvSceneClear();

        // @ts-ignore
        // this.scene = this.camera = this.renderer = this.controls = undefined;
    }

    private createAmbientLight(color: number, intensity: number) {
        const ambientLight = new AmbientLight(color, intensity);
        ambientLight.name = 'ambientLight';
        this.scene.add(ambientLight);
    }

    private updateOrCreateDirectionalLight(name: string, position: Vector3, color: number, intensity: number) {
        const light = this.scene.getObjectByName(name);

        if (light) {
            light.position.copy(position);
            return;
        }

        const directionalLight = new DirectionalLight(color, intensity);
        // directionalLight.castShadow = true;
        directionalLight.position.copy(position);
        directionalLight.name = name;
        this.scene.add(directionalLight);
    }

    private rotateModel(rotations: Rotation[], mesh: Object3D) {
        mesh.matrixAutoUpdate = false;

        const matrix = this.sourceModelMatrix!.clone();

        rotations.forEach(rotation => {
            const realAngle = MathUtils.degToRad(rotation.angle);
            let worldAxis: Vector3;

            const inverseMatrix = matrix.clone().invert();
            switch (rotation.axis) {
                case 'x':
                    worldAxis = new Vector3(1, 0, 0).applyMatrix4(inverseMatrix);
                    break;
                case 'y':
                    worldAxis = new Vector3(0, 1, 0).applyMatrix4(inverseMatrix);
                    break;
                case 'z':
                    worldAxis = new Vector3(0, 0, 1).applyMatrix4(inverseMatrix);
                    break;
            }

            const rotationWorld = new Matrix4().makeRotationAxis(worldAxis, realAngle);
            matrix.multiply(rotationWorld);
        });

        this.currentModel!.matrix = matrix;

        this.requestRenderIfNotRequested();
    }

    private changeObjectMaterial(object: Object3D, material: ModelMaterials) {
        object.traverse(child => {
            if (child instanceof Mesh) {
                child.material = material;
            }
        });
    }

    changeObjectActivity(name: ModelViewerObjects, activity?: boolean) {
        const object = this.objects[name];
        if (!object) return;

        object.active = object.object.visible = activity ?? !object.active;

        this.requestRenderIfNotRequested();
    }

    changeObjectVisibility(object: Object3D, visibility?: boolean) {
        object.visible = visibility ?? !object.visible;
    }

    private attachObject(name: ModelViewerObjects, parent: Object3D) {
        const { active, draw, color } = this.config.objects[name];

        if (!draw) return;

        let object;

        switch (name) {
            case ModelViewerObjects.Axes: {
                object = new Axes(this.fieldSize / 2);
                break;
            }
            case ModelViewerObjects.Grid: {
                object = new Grid(this.fieldSize);
                break;
            }
            case ModelViewerObjects.ModelEdges: {
                object = new ModelEdges(this.currentModel!, color);
                break;
            }
            case ModelViewerObjects.ModelBoundingBox: {
                object = new ModelBoundingBox(this.currentModelBBox);
                break;
            }
            case ModelViewerObjects.PrinterBoundingBox: {
                object = new PrinterBoundingBox(this.config.objects[name].size);
                break;
            }
            case ModelViewerObjects.Model: {
                return;
            }
            default: {
                const _exhaustiveCheck: never = name;
                return;
            }
        }

        // @ts-ignore
        this.objects[name] = { object, active };

        object.name = name;
        this.changeObjectVisibility(object, active && this.solidModeActive);
        parent.add(object);
    }

    private detachObject(name: ModelViewerObjects) {
        const object = this.objects[name];
        if (!object) return;

        objectCleanup(object.object);
        object.object.removeFromParent();
    }

    private changeSceneBackground(background: Scene['background'] | string) {
        if (typeof background === 'string') {
            this.scene.background = new Color(background);
        } else {
            this.scene.background = background;
        }
    }

    debug() {
        if (!this.config.debug) return;

        const scene = this.scene;

        let objects = 0,
            vertices = 0,
            triangles = 0;

        for (let i = 0, l = scene.children.length; i < l; i++) {
            const object = scene.children[i];

            // eslint-disable-next-line no-loop-func
            object.traverseVisible(object => {
                objects++;

                if ((object as Mesh).isMesh) {
                    const geometry = (object as Mesh).geometry;

                    vertices += geometry.attributes.position.count;

                    if (geometry.index !== null) {
                        triangles += geometry.index.count / 3;
                    } else {
                        triangles += geometry.attributes.position.count / 3;
                    }
                }
            });
        }

        const format = (value: number) => value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');

        if (process.env.NODE_ENV !== 'production') {
            console.log('info:', this.renderer?.info);
            console.log('objects:', format(objects));
            console.log('vertices:', format(vertices));
            console.log('triangles:', format(triangles));
        }
    }
}
