import type { AxiosRequestConfig, AxiosError, AxiosResponse } from 'axios';
import type { BaseQueryFn } from '@reduxjs/toolkit/query';
import { createApi } from '@reduxjs/toolkit/query/react';
import { processRequestError, requestParamsSerializer } from '@utils';
// import { REHYDRATE } from 'redux-persist';
import { RootState } from '@types';
import { axiosInstance } from '../axios-instance';
import { extendQueryParams } from './helpers';

export type FetchBaseQueryError = {
    status?: AxiosResponse['status'];
    data?: AxiosResponse['data'] | AxiosError['message'];
    message?: string;
};
export type FetchBaseQueryArgs = {
    url: string;
    body?: AxiosRequestConfig['data'] | FormData;
} & AxiosRequestConfig;

// we use axios because of onUploadProgress https://stackoverflow.com/a/73186578

const baseQuery: BaseQueryFn<FetchBaseQueryArgs, unknown, FetchBaseQueryError> = async (
    { body, params, ...args },
    { signal, getState, endpoint },
) => {
    const token = (getState() as RootState).auth.token;

    if (token) {
        (axiosInstance.defaults as any).headers['Authorization'] = `Token ${token}`;
    }

    params = extendQueryParams(endpoint, params);

    try {
        const result = await axiosInstance({
            data: body,
            params,
            signal,
            paramsSerializer: requestParamsSerializer,
            ...args,
        });
        return { data: result.data };
    } catch (error) {
        return {
            error: processRequestError(error),
        };
    }
};

export const dfApi = createApi({
    reducerPath: 'dfApi',
    baseQuery,
    endpoints: () => ({}),
    tagTypes: [
        'BackofficeAppSettings',
        'Integrations',
        'Order',
        'Organization',
        'OrganizationSuggestion',
        'Client',
        'Emails',
        'AdminTechnologies',
        'Machines',
    ],
    // extractRehydrationInfo(action, { reducerPath }) {
    //     if (action.type === REHYDRATE) {
    //         return action.payload[reducerPath];
    //     }
    // },
});
