import { isAxiosError } from 'axios';

export function processRequestError(error: unknown) {
    return isAxiosError(error)
        ? {
              status: error.response?.status,
              data: error.response?.data,
              message: error.response?.data?.detail || error.message,
          }
        : undefined;
}
