import { useEffect, useReducer, useRef, useState } from 'react';
import { useQueryParams, StringParam, withDefault } from 'use-query-params';
import { useAppSelector } from '@app/hooks';
import { selectContentCustomization } from '@modules/app';
import { ModelViewerDarkModeBox } from '@pages/widget/order/viewer/model-viewer/model-viewer-dark-mode-box';
import { ModelViewerSceneControlsPanel } from '@pages/widget/order/viewer/model-viewer/model-viewer-scene-controls-panel';
import { ModelViewer, ModelViewerObjects } from '@utils';
import { useGetResizeRef } from '@utils/parant-site-comunicator';

import styles from '../widget/order/viewer/viewer-window.module.scss';

enum PageParams {
    ModelFileUrl = 'modelFileUrl',
    WallThicknessFileUrl = 'wallThicknessFileUrl',
}

export function useViewerUrls() {
    const [query] = useQueryParams(
        {
            [PageParams.ModelFileUrl]: withDefault(StringParam, undefined),
            [PageParams.WallThicknessFileUrl]: withDefault(StringParam, undefined),
        },
        { removeDefaultsFromUrl: true },
    );

    return query;
}

export const ModelViewerPage = () => {
    let visualizer: HTMLDivElement;
    const modelViewer = useRef<ModelViewer>();
    const [isModelSet, setIsModelSet] = useState(false);
    const contentCustomization = useAppSelector(selectContentCustomization);

    const [isDarkModeActive, toggleDarkMode] = useReducer(previous => !previous, false);

    useEffect(() => {
        modelViewer.current = new ModelViewer(visualizer, {
            darkModeOn: isDarkModeActive,
            debug: true,
            objects: {
                [ModelViewerObjects.Axes]: {
                    draw: true,
                    active: true,
                },
                [ModelViewerObjects.Grid]: {
                    draw: true,
                    active: true,
                },
            },
        });

        return () => {
            modelViewer.current?.clear();
            modelViewer.current = undefined;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { modelFileUrl, wallThicknessFileUrl } = useViewerUrls();

    useEffect(() => {
        if (!modelFileUrl || !modelViewer.current) return;

        const { promise, cancel } = modelViewer.current.loadModel(modelFileUrl);

        promise.then(() => {
            setIsModelSet(true);
        });

        return () => {
            cancel();
        };
    }, [modelFileUrl]);

    // preload walls
    useEffect(() => {
        if (!isModelSet || !wallThicknessFileUrl || !modelViewer.current) return;

        const { promise, cancel } = modelViewer.current.loadThinWalls(wallThicknessFileUrl);
        promise.then(() => {
            modelViewer.current!.highlightThinWallsOn = true;
        });

        return () => {
            cancel();
        };
    }, [wallThicknessFileUrl, isModelSet]);

    const containerRef = useGetResizeRef();

    return (
        <>
            <div className={styles.box} tabIndex={-1} ref={containerRef}>
                <div
                    className={styles.inner}
                    ref={ref => {
                        visualizer = ref as HTMLDivElement;
                    }}
                >
                    <ModelViewerDarkModeBox
                        show
                        isDarkModeActive={isDarkModeActive}
                        toggleDarkMode={toggleDarkMode}
                        modelViewer={modelViewer.current}
                    />
                    <ModelViewerSceneControlsPanel
                        show
                        contentCustomization={contentCustomization}
                        modelViewer={modelViewer.current}
                    />
                </div>
            </div>
        </>
    );
};
