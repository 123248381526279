import * as Three from 'three';
import type { Box3, Vector3 } from 'three';
import { ModelViewerVariables } from '../constants';

const _center = new Three.Vector3();

export class PrinterBoundingBox extends Three.Box3Helper {
    static updateBox(box: Box3, size: Vector3 | string) {
        if (typeof size === 'string') {
            size = new Three.Vector3().fromArray(size.split('-').map(side => parseInt(side)));
        }

        const center = _center.copy(size).multiplyScalar(0.5);

        box.setFromCenterAndSize(center, size);
    }

    constructor(size?: Vector3 | string) {
        const box = new Three.Box3();

        if (size) {
            PrinterBoundingBox.updateBox(box, size);
        }

        super(box, new Three.Color(ModelViewerVariables.PrinterBox.Color));

        // this.material.depthTest = false;
        this.renderOrder = 2;
    }

    updateSize(size: Vector3 | string) {
        PrinterBoundingBox.updateBox(this.box, size);
    }

    checkModelContains(box: Box3) {
        const contains = this.box.containsBox(box);

        this.material.color = contains
            ? new Three.Color(ModelViewerVariables.PrinterBox.Color)
            : new Three.Color(ModelViewerVariables.PrinterBox.HighlightColor);

        return contains;
    }
}
